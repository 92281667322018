import { Button, Container, Divider, Group, Paper, Select, Table, Text, TextInput, Title } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { showNotification } from '@mantine/notifications';
import { DateTime } from 'luxon';
import { FormEvent, useEffect, useState } from 'react';
import { InferType } from 'yup';
import { submissionSchema, useSubmissionForm } from '../../../pages/register/schema';
import { useSubmissionMutation } from '../../hooks/useSubmissionMutation';
import { useSupportingDocuments } from '../../hooks/useSupportingDocuments';
import { useTradeTransactions } from '../../hooks/useTradeTransactions';
import { GroupMemberTypeText, SubmissionSchemaV2, SubmissionStatus } from '../../types';
import { Acknowledgement } from '../acknowledgement';
import { DeleteTradeDialog } from '../delete-trade-dialog';
import { SupportingDocuments } from '../supporting-documents';
import { TradeDetailsModal } from '../trade-detail-modal';

interface SubmissionFormProps {
  submissionId: string;
  initialData: SubmissionSchemaV2;
  submissionStatus: SubmissionStatus;
}

export const SubmissionForm = ({ initialData, submissionId, submissionStatus }: SubmissionFormProps) => {
  const [page, setPage] = useState<number>(1);
  const [tradeDeleteId, setTradeDeleteId] = useState<string | null>(null);
  const [isOpen, { open: openModal, close: closeModal }] = useDisclosure(false);
  const [isOpenDeleteTrade, { open: openDeleteModal, close: closeDeleteModal }] = useDisclosure(false);
  const { getInputProps, onSubmit, values, clearErrors, errors } = useSubmissionForm(initialData);
  const { data: tradeData, refetch: refetchTrade } = useTradeTransactions(submissionId);
  const { mutate, isLoading: isSaving } = useSubmissionMutation();
  const { data: attachedDocumentsData } = useSupportingDocuments(submissionId);
  const gmType = values.memberType;
  const handleSubmit = (data: InferType<typeof submissionSchema>, event: FormEvent<HTMLFormElement>) => {
    const action = (event.nativeEvent as SubmitEvent).submitter as HTMLButtonElement;

    if (values.memberType === 'RETAIL_INVESTOR' && tradeData?.transactions.length === 0) {
      showNotification({
        id: 'empty-trade',
        title: 'Error',
        message: 'Atleast 1 transaction data is required.',
        autoClose: 5000,
        color: 'red',
      });
      return;
    }

    if (
      (values.memberType === 'INSTITUTIONAL_INVESTOR' || values.memberType === 'RETAIL_INVESTOR_AGENT') &&
      attachedDocumentsData?.attachments.length === 0
    ) {
      showNotification({
        id: 'empty-trade',
        title: 'Error',
        message: 'Please attach a supporting document for your submission',
        autoClose: 5000,
        color: 'red',
      });
      return;
    }

    mutate({
      id: submissionId,
      submission: {
        ...data,
        contactDetails: {
          ...data.contactDetails,
          relationship: data.contactDetails.relationship as string,
        },
        memberType: data.memberType,
        legalEntity: data.legalEntity ?? '',
        beneficiary: data.beneficiary,
        abnAcn: data.abnAcn,
        tradingAccount: data.tradingAccount ?? '',
        openingBalance: data.openingBalance,
        actionType: action.value,
      },
    });
  };

  const saveDraft = () => {
    mutate({
      id: submissionId,
      submission: {
        ...values,
        contactDetails: {
          ...values.contactDetails,
          relationship: values.contactDetails.relationship as string,
        },
        memberType: values.memberType,
        legalEntity: values.legalEntity ?? '',
        beneficiary: values.beneficiary,
        abnAcn: values.abnAcn,
        tradingAccount: values.tradingAccount ?? '',
        openingBalance: values.openingBalance,
        actionType: 'DRAFTED',
      },
    });
  };

  const onPromptDelete = (tradeId: string) => {
    setTradeDeleteId(tradeId);
    openDeleteModal();
  };

  const onCloseDeleteTrade = () => {
    setTradeDeleteId(null);
    closeDeleteModal();
  };

  useEffect(() => {
    clearErrors();
  }, [gmType]);

  return (
    <>
      <form onSubmit={onSubmit((data, event) => handleSubmit(data, event))}>
        <Paper
          shadow="xl"
          sx={{
            padding: '2rem',
          }}
        >
          <Container sx={{ textAlign: 'center' }}>
            <Title order={3}>
              PLEASE COMPLETE THIS FORM IF YOU WANT TO REGISTER FOR THE IAG SHAREHOLDER CLASS ACTION.
            </Title>
            <Text size="md" py="xl">
              If you are unsure whether you are a group member of the IAG Shareholder Class Action, or if you have any
              questions about completing this form, please contact Quinn Emanuel by emailing{' '}
              <span>
                <a href="mailto:register@iagclassaction.com">register@iagclassaction.com</a>
              </span>{' '}
              or phoning <a href="tel:(02) 9146 3637">(02) 9146 3637</a>.
            </Text>
          </Container>
          <Title order={2}>Part A - Contact Details</Title>
          <Divider orientation="horizontal" size={'md'} variant="solid" sx={{ height: '1rem' }} color="gray" />
          <div
            style={{
              display: 'flex',
              gap: '2rem',
              marginBottom: '1rem',
            }}
          >
            <TextInput label="First Name" sx={{ width: '100%' }} {...getInputProps('contactDetails.firstName')} />
            <TextInput label="Last Name" sx={{ width: '100%' }} {...getInputProps('contactDetails.lastName')} />
          </div>
          <div
            style={{
              display: 'flex',
              gap: '2rem',
              marginBottom: '1rem',
            }}
          >
            <TextInput label="Company" sx={{ width: '100%' }} {...getInputProps('contactDetails.company')} />
            <TextInput label="Position" sx={{ width: '100%' }} {...getInputProps('contactDetails.position')} />
          </div>
          <div
            style={{
              display: 'flex',
              gap: '2rem',
              marginBottom: '1rem',
            }}
          >
            <TextInput label="Email" sx={{ width: '100%' }} {...getInputProps('contactDetails.email')} />
            <TextInput label="Phone" sx={{ width: '100%' }} {...getInputProps('contactDetails.phone')} />
          </div>
          <div
            style={{
              display: 'flex',
              gap: '2rem',
              marginBottom: '1rem',
            }}
          >
            <Select
              label="Relationship to Group Member"
              sx={{ width: '49%' }}
              data={[
                {
                  value: 'INDIVIDUAL',
                  label: 'I am the Group Member (Group Member is an Individual)',
                },
                {
                  value: 'PARTNER',
                  label: 'I am a partner (Group Member is a Partnership)',
                },
                {
                  value: 'DIRECTOR',
                  label: 'I am a director (Group Member is a Company - trustee or other)',
                },
                {
                  value: 'TRUSTEE',
                  label: 'I am the trustee (Group Member is a Trust)',
                },
                {
                  value: 'AGENT',
                  label: 'I am an authorised agent of the Group Member',
                },
              ]}
              {...getInputProps('contactDetails.relationship')}
            />
          </div>
        </Paper>
        <Paper
          shadow="xl"
          sx={{
            padding: '2rem',
            marginTop: '1rem',
          }}
        >
          <br />
          <Title order={2}>Part B - Group Member Claim Data</Title>
          <Divider orientation="horizontal" size={'md'} variant="solid" sx={{ height: '1rem' }} color="gray" />
          <Select
            label={<Title order={4}>Group Member Type</Title>}
            // sx={{ width: '50%' }}
            data={[
              {
                value: 'RETAIL_INVESTOR',
                label: 'Retail Investor',
              },
              {
                value: 'INSTITUTIONAL_INVESTOR',
                label: 'Institutional Investor / Agent of',
              },
              {
                value: 'RETAIL_INVESTOR_AGENT',
                label: 'Retail Investor Agent',
              },
            ]}
            {...getInputProps('memberType')}
          />
          {(values.memberType === 'INSTITUTIONAL_INVESTOR' || values.memberType === 'RETAIL_INVESTOR_AGENT') && (
            <Text>
              <p style={{ margin: '0.25rem', fontSize: '0.85rem', color: 'black' }}>
                If you are responsible for multiple group member registrations with large amounts of trades (more easily
                provided with spreadsheets), please download the following{' '}
                <a href="https://cdn.iagclassaction.com/uploads/IAG - template - Part B - Group Member Claim Data.xlsx">
                  template
                </a>
                , complete and upload using the Supporting Documents section below. By completing this template
                spreadsheet and providing the corresponding information for the group members you are responsible for,
                you are no longer required to complete the remaining fields of this section.
              </p>
            </Text>
          )}

          <br />
          <div>
            <Title order={4}>Legal Details</Title>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '0.5rem',
                marginBottom: '1rem',
              }}
            >
              <TextInput
                label="Full Name of the Legal Entity"
                description={
                  <p style={{ margin: '0.25rem', fontSize: '0.85rem', color: 'black' }}>
                    Recorded as the shareholder in the IAG share registry
                  </p>
                }
                sx={{ width: '100%' }}
                {...getInputProps('legalEntity')}
              />
              <TextInput
                label="Full Name of Beneficial Owner (If different to what is recorded in the IAG share registry)"
                description={
                  <>
                    {(gmType === 'INSTITUTIONAL_INVESTOR' || gmType === 'RETAIL_INVESTOR_AGENT') && (
                      <p style={{ margin: '0.25rem', fontSize: '0.85rem', color: 'black' }}>
                        Selecting <strong>Institutional Investor or Retail Investor Agent</strong> will make:
                        <ol style={{ marginTop: 0 }}>
                          <li>the rest of Part B not compulsory;</li>
                          <li>
                            the Trade Data table and Opening Balance not compulsory and cause additional content to
                            display before the Opening Balance section.
                          </li>
                          <li>Supporting Documents section becomes compulsory;</li>
                        </ol>
                      </p>
                    )}

                    <p style={{ margin: '0.25rem', fontSize: '0.85rem', color: 'black' }}>
                      Name of Company, entity or person who owned or was beneficially entitled to the Shares and on
                      whose behalf this form is being completed.
                    </p>
                  </>
                }
                sx={{ width: '100%' }}
                {...getInputProps('beneficiary')}
              />
              <TextInput label="ABN/ACN (If Applicable)" sx={{ width: '100%' }} {...getInputProps('abnAcn')} />
              <TextInput
                label="Trading Account HIN/SRN"
                description={
                  <>
                    <p style={{ margin: '0.25rem', fontSize: '0.85rem', color: 'gray' }}>
                      You can find the HIN/SRN number on your trade confirmation statement. It will either be 10 or 11
                      characters in length. If it is 11 characters in length, the HIN or SRN begins with the letter i, n
                      or x. The remaining 10 characters are numbers. For example, i0012345678, n0012345678, x0012345678.
                    </p>
                    <p style={{ margin: '0.25rem', fontSize: '0.85rem', color: 'gray' }}>
                      If you have not retained your holding statement(s), you can request this number from your
                      financial advisor, stockbroker, CHESS Sponsor, Issuer Sponsor, or IAG's share registry,
                      Computershare Investor Services Pty Limited.
                    </p>
                  </>
                }
                sx={{ width: '100%' }}
                {...getInputProps('tradingAccount')}
              />
            </div>
          </div>
          <div>
            <Title order={4}>Address Details</Title>
            <div>
              <TextInput label="Address" sx={{ width: '100%' }} {...getInputProps('address.address')} />
            </div>
            <Group sx={{ marginTop: '1rem' }} grow>
              <TextInput label="Suburb/Town" {...getInputProps('address.suburban')} />
              <TextInput label="State" {...getInputProps('address.state')} />
              <TextInput label="Post Code" {...getInputProps('address.postCode')} />
              <TextInput label="Country" {...getInputProps('address.country')} />
            </Group>
          </div>
        </Paper>
        <Paper
          shadow="xl"
          sx={{
            padding: '2rem',
            marginTop: '1rem',
          }}
        >
          <Title order={2}>Part C - Group Member Trade Data</Title>
          <Divider orientation="horizontal" size={'md'} variant="solid" sx={{ height: '1rem' }} color="gray" />
          <Title order={4}>Group Member Type: {GroupMemberTypeText(values.memberType)}</Title>
          <Text>
            If you are responsible for multiple group member registrations with large amounts of trades (more easily
            provided with spreadsheets), please download the following{' '}
            <a href="https://cdn.iagclassaction.com/uploads/IAG - template - Part C - Group Member Trade Data.xlsx">
              template
            </a>
            , complete and upload using the Supporting Documents section below. By completing this template spreadsheet
            and providing the corresponding information for the group members you are responsible for, you are no longer
            required to complete the remaining fields of this section.
          </Text>

          <br />
          <div>
            <TextInput
              label={<Title order={4}>Opening Balance</Title>}
              description={
                <Text color="black">
                  The opening balance is the number of shares held as at close of trading on 10 March 2020. The opening
                  balance can be accessed from your transaction history statement. Alternatively, your financial
                  advisor, stockbroker, CHESS Sponsor, Issuer Sponsor, or IAG's share registry, Computershare Investor
                  Services Pty Limited.
                </Text>
              }
              sx={{ width: '100%' }}
              {...getInputProps('openingBalance')}
            />
          </div>
          <Title sx={{ marginTop: '1rem' }} order={4}>
            Transaction Data
          </Title>
          <Text color="black">
            Please provide details of the acquisition(s) and sale(s) and/or disposal(s) of IAG shares as follows:
            <ul
              style={{
                marginTop: 0,
                marginBottom: 0,
              }}
            >
              <li>Shares bought between 11 March 2020 and 20 November 2020 (inclusive); and</li>
              <li>Shares sold between 11 March 2020 and 20 November 2020 (inclusive).</li>
            </ul>
            Please enter each trade separately below using Australian dollars.
          </Text>
          <Table striped sx={{ marginTop: '1rem' }}>
            <thead>
              <tr>
                <th align="center">Trade Date</th>
                <th align="center">Transaction Type</th>
                <th align="center">Quantity</th>
                <th align="center">Price Per Share</th>
                <th align="center">Total Amount Paid</th>
                <th align="center">Sub Type</th>
                <th align="center">Action</th>
              </tr>
            </thead>
            <tbody>
              {(tradeData?.transactions ?? []).map(t => (
                <tr key={t.id}>
                  <td align="center">{DateTime.fromFormat(t.transactionDate, 'dd/MM/yyyy').toFormat('dd-MMM-yyyy')}</td>
                  <td align="center">{t.transactionType}</td>
                  <td align="center">{t.quantity}</td>
                  <td align="center">{t.sharePrice}</td>
                  <td align="center">{t.amountPaid}</td>
                  <td align="center">{t.subType}</td>
                  <td>
                    <Button type="button" color="red" size="xs" onClick={() => onPromptDelete(t.id)}>
                      Delete
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginTop: '2rem',
            }}
          >
            <Button type="button" color="gray" onClick={openModal}>
              Add Row
            </Button>
          </div>
        </Paper>
        {submissionId && <SupportingDocuments submissionId={submissionId} />}
        <Acknowledgement />
        <br />
        <div style={{ margin: '1rem', display: 'flex', gap: '1rem', justifyContent: 'center', alignItems: 'center' }}>
          {submissionStatus === SubmissionStatus.DRAFTED && (
            <Button type="button" variant="outline" onClick={() => saveDraft()}>
              Save Draft
            </Button>
          )}

          <Button type="submit" color="blue" value={SubmissionStatus.SUBMITTED} loading={isSaving}>
            Submit
          </Button>
          <Button component="a" href="/app/home" color="red">
            Cancel
          </Button>
        </div>
        <br />
      </form>
      <TradeDetailsModal submissionId={submissionId} isOpen={isOpen} onClose={closeModal} refetch={refetchTrade} />
      {!!tradeDeleteId && isOpenDeleteTrade && (
        <DeleteTradeDialog
          tradeId={tradeDeleteId}
          submissionId={submissionId}
          isOpen={isOpenDeleteTrade}
          onClose={onCloseDeleteTrade}
          refetch={refetchTrade}
        />
      )}
    </>
  );
};
